//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from "@/components/Search";
import Product from "@/components/Product";

export default {
  name: "Home",
  components: { Search, Product },

  created() {
    console.log("about us.");

    this.getuniacid();
    if (this.$route.query.code) {
      console.log("leimu:", this.$route.query.code);
      this.nowleimu = this.$route.query.code;
    }

    //this.getGoodsList()
  },
  methods: {
    // 获取首頁模块
    async getuniacid() {
      // this.uniacid= 4 ;
      var ss = window.localStorage.getItem("webinfo");

      var ssarray = JSON.parse(ss);
      console.log("ss", ss);
      console.log("ssarray.ucid", ssarray["ucid"]);
      this.webset = ssarray;
    },
  },
};
